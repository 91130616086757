@charset "utf-8";

// ////////////////////////////////////////////////////////////////////////////////
//
//  style
//
// ////////////////////////////////////////////////////////////////////////////////

@import "../../core/_variable.scss";
@import "base_default";
@import "base_common";

@import "style_top_about";
@import "style_talk";
@import "style_welfare";
@import "style_number";
@import "style_office";
@import "style_voice";
