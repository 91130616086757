@charset "UTF-8";
@import "../../core/_variable.scss";

/* basic
------------------------------------------------------------ */
.abs            { position:absolute; }
.hide           { visibility:hidden; }
.show           { visibility:visible; }
.bold           { font-weight : bold; }
.left           { float:left; }
.right          { float:right; }
#__bs_notify__  { display: none !important; }



/* float
------------------------------------------------------------ */
.float { display: block; }
.float:before, .float:after{ content: " "; display: table; }
.float:after{ clear: both; }
.float { zoom: 1; *zoom: 1; }// For IE 6/7 (trigger hasLayout)



/* flexbox
------------------------------------------------------------ */
@mixin flexbox{
    -js-display       : flex;
    display           : block;
    display           : -webkit-box;
    display           : -webkit-flex;
    display           : -moz-box;
    display           : -ms-flexbox;
    display           : flex;
    -webkit-flex-wrap : wrap;
    -ms-flex-wrap     : wrap;
    flex-wrap         : wrap;
}
.flexbox{
    -js-display       : flex;
    display           : block;
    display           : -webkit-box;
    display           : -webkit-flex;
    display           : -moz-box;
    display           : -ms-flexbox;
    display           : flex;
    -webkit-flex-wrap : wrap;
    -ms-flex-wrap     : wrap;
    flex-wrap         : wrap;
}
.flexbox{ -webkit-flex-wrap: wrap; flex-wrap: wrap; }
.flexbox li{ justify-content: flex-start; }
.flexbox .box{ justify-content: flex-start; }
.flexbox.between{ justify-content: space-between; }

.flex-dl{
    display   : flex;
    flex-wrap : wrap;
}



/* box-sizing
------------------------------------------------------------ */
*, *:before, *:after{
    -webkit-box-sizing : border-box;
    -moz-box-sizing    : border-box;
    -o-box-sizing      : border-box;
    -ms-box-sizing     : border-box;
    box-sizing         : border-box;
}



/* other
------------------------------------------------------------ */
.js-switching{ /* js用予約語 */ }
.trans{
    -webkit-transition : all 0.25s ease;
    -moz-transition    : all 0.25s ease;
    -o-transition      : all 0.25s ease;
    -ms-transition     : all 0.25s ease;
    transition         : all 0.25s ease;
}

.table-cell{ display : table; }
.table-cell .cell{ display : table-cell; }
//.fixed{ position : fixed; width : 100%; height : 100%; }
.btn-disabled{ pointer-events : none; cursor : default; }
.inline-group{
    display : block;
    .inline{ display : inline-block; }
}

.fit{ width: 100%; }
.pcshow{ display : block; }
.spshow{ display : none; }
@media #{$small}{
    .pcshow{ display : none; }
    .spshow{ display : block; }
    .sp100{ width : 100%; }
}

/* @see https://qiita.com/Shinji-m/items/69a392685699f3ba95fb */
.valign-center{
    display         : -webkit-flex;
    display         : flex;
    align-items     : center;
    //justify-content : center;// 左右中央揃えの場合はつける
}
@mixin valign-center{
    display         : -webkit-flex;
    display         : flex;
    align-items     : center;
    //justify-content : center;
}