@charset "utf-8";
@import "../../core/_variable.scss";

/* ================================================================================
    layout
================================================================================ */
#__bs_notify__  { display: none !important; }
[class*=inner]{ position : relative; margin : 0 auto; }
.inner{
    &640{ width : 640px; }
    &800{ width : 800px; }
    &1000{ width : 1000px; }
    &1100{ width : 1100px; }
    &1200{ width : 1200px; }
}

@media #{$small}{
    [class*=inner]{ width : auto; margin : auto; }
}



/* ================================================================================
    .bnr_area
================================================================================ */
.bnr_area{
    li{
        @include nthTopMarginZero;
        margin : 20px 0 0;
    }
    text-align : center;
    & > a{
        display : inline-block !important;
        @include trans(0.3);
        &:hover{ opacity : 0.7; }
    }
}

.bnr_area_custom{
    margin     : 40px 0 0;
    text-align : center;
    a{
        display : inline-block;
        @include trans(0.3);
        &:hover{ opacity : 0.8; }
    }
}

.bnr_area_session{
    margin : 35px auto 0;
    width  : 728px;
    .ttl{
        @include fonts(18px, 1em, $fontweightBold);
        text-align : center;
    }
    .txt{
        margin : 15px 0 0;
        p{ @include fonts(14px, 26px, null, 0); }
    }
    .list{
        margin : 35px 0 0;
        li:nth-of-type(n+2){ margin : 20px 0 0; }
    }
}

#main.new_talk,
#main.new_flow,
#main.qa{
    .bnr_area_session{ margin-bottom : 60px; }
}

@media #{$small}{
    .bnr_area{
        li{ margin : 15px 0 0; }
    }

    .bnr_area_custom{ margin : 20px 0 0; }

    .bnr_area_session{
        margin : 20px 0 0;
        width  : 100%;
        .ttl{ @include fonts(15px, 1em); }
        .txt{
            margin : 10px 0 0;
            p{ @include fonts(12px, 20px); }
        }
        .list{
            margin : 20px 0 0;
            li:nth-of-type(n+2){ margin : 15px 0 0; }
        }
    }

    #main.new_talk,
    #main.new_flow,
    #main.qa{
        .bnr_area_session{
            padding       : 0 20px;
            margin-bottom : 40px;
        }
    }
}



/* ================================================================================
    modaal
================================================================================ */
.modal-btn:focus{ outline : none; }

.modaal{
    &-window{ display : none; }
    &-wrapper{ z-index : 99999 !important; }
    &-overlay{ z-index : 88888 !important; }
    &-content-container{ padding : 0; }
    &-container{
        box-shadow : none;
        max-width  : 1000px;
        background : none !important;
    }
}

.modal-body{
    position : relative;

    .video{
        position       : relative;
        width          : 100%;
        height         : auto;
        padding-bottom : 56.23%;
        iframe{
            @include absoluteTL;
            width  : 100%;
            height : 100%;
        }
    }

    .modal-inline--close{
        z-index: 9999999999;
        @include absolute(null, 0, -80px, 0, auto);
        width  : 52px;
        height : 52px;
        cursor : pointer;
        &:before, &:after{
            content    : '';
            @include absolute(0, 0, 0, 0, auto);
            width      : 70px;
            height     : 2px;
            background : #fff;
        }
        &:before{ transform : rotate(45deg); }
        &:after{ transform : rotate(-45deg); }
    }
}

@media #{$small}{
    .modal-body{
        padding : 5px;

        .inside{
            padding : 30px 15px 20px;
            .ttl{
                margin  : 0 0 10px;
                padding : 0 0 15px;
                @include fonts(14px, 1.4em);
                &:before{ width : 30px; }
                .small{ font-size : 12px; }
            }
            .sttl{
                @include fonts(16px, 1.4em);
                .small{ font-size : 13px; }
            }
            .txt{
                margin : 20px 0 0;
                @include fonts(12px, 1.6em);
            }
        }

        .modal-inline--close{
            @include absolute(null, 0, -40px, 0, auto);
            width  : 30px;
            height : 30px;
            &:before, &:after{
                width  : 20px;
                height : 2px;
            }
        }
    }
}

