@charset "utf-8";
@import "../../core/_variable.scss";

/* ================================================================================
    #main.new_talk
================================================================================ */
$color-talk01 : #3dbdea;
$color-talk02 : #e88520;
$color-talk03 : #f656a1;

#main.new_talk{
    #h1{
        margin : 0 0 50px;
        h1{
            position   : relative;
            padding    : 60px 0;
            border     : 1px solid #dcdcdc;
            @include fonts(40px, 1em, null, 0, #74b892);
            text-align : center;
            .small{
                @include fonts(24px, 1em, null, 0);
                transform : translate(15px, -3px);
            }

            &:before{
                content         : '';
                @include absolute(0, null, 0, 40px, auto);
                width           : 71px;
                height          : 52px;
                background      : url(../images/talk/talk_icon_h1_pc.png) no-repeat 0 0;
                background-size : 100% auto !important;
            }
        }
    }

    #talk-profile{
        position : relative;
        padding  : 390px 40px 0;
        .bg{
            z-index         : -1;
            @include absoluteTL;
            width           : 100%;
            height          : 500px;
            background      : url(../images/talk/talk_mv_pc.jpg) no-repeat center;
            background-size : cover !important;
        }

        .profile{
            li{
                &:nth-of-type(n+2){ margin-left : 3.3%; }
                &:nth-of-type(1){
                    border-top : 4px solid $color-talk01;
                    .name p{ color : $color-talk01; }
                }
                &:nth-of-type(2){
                    border-top : 4px solid $color-talk02;
                    .name p{ color : $color-talk02; }
                }
                &:nth-of-type(3){
                    border-top : 4px solid $color-talk03;
                    .name p{ color : $color-talk03; }
                }

                padding       : 30px 30px 35px;
                width         : 48.2%;
                background    : #f6f6f6;
                border-right  : 1px solid #dcdcdc;
                border-bottom : 1px solid #dcdcdc;
                border-left   : 1px solid #dcdcdc;

                .name{
                    p{ @include fonts(24px, 1em, $fontweightLight, 0); }
                }
                .occupation{
                    margin : 10px 0 0;
                    p{ @include fonts(12px, 1em, $fontweightRegular, 0); }
                }
                .txt{
                    margin : 20px 0 0;
                    p{
                        @include fonts(12px, 22px, $fontweightRegular, 0);
                        @include textJustify;
                    }
                }
            }
        }
    }

    #talk-main{
        margin : 45px 0 0;
        .block{
            &:nth-of-type(n+2){ margin : 40px 0 0; }
            padding : 0 40px 45px;
            border  : 1px solid #74b892;
            .h2{
                height     : 100px;
                background : #74b892;
                text-align : center;
                @include flex(center, center);
                h2{
                    position : relative;
                    @include fonts(28px, 1em, $fontweightSemiBold, 0.05em, #fff, $fontFamilyNotoSerif);
                    &:before,
                    &:after{
                        content    : '';
                        @include absolute(0, null, 0, null, auto);
                        width      : 10px;
                        height     : 10px;
                        background : #fff;
                    }
                    &:before{ left : -30px; }
                    &:after{ right : -30px; }
                }
            }

            .txtbody{
                margin : 80px 0 0;
                .h3{
                    position : relative;
                    margin   : 0 0 30px;
                    padding  : 0 0 0 70px;
                    &:before{
                        content    : '';
                        @include absolute(0, null, 0, 0, auto);
                        width      : 15px;
                        height     : 15px;
                        background : #74b892;
                    }
                    &:after{
                        content    : '';
                        @include absolute(0, null, 0, 15px, auto);
                        width      : 45px;
                        height     : 1px;
                        background : #74b892;
                    }
                    h3{ @include fonts(18px, 1em, $fontweightSemiBold, 0.05em, $colorBlack, $fontFamilyNotoSerif); }
                }

                .flexbox.img{
                    &--right{
                        .col:nth-of-type(1){ width : calc(100% - 320px); }
                        .col:nth-of-type(2){
                            width : 320px;
                            .ph{ transform : translate(40px); }
                        }
                    }
                    &--left{
                        .col:nth-of-type(1){
                            width : 320px;
                            .ph{ transform : translate(-40px); }
                        }
                        .col:nth-of-type(2){ width : calc(100% - 320px); }
                    }
                }

                .talk-list{
                    li{
                        &:nth-of-type(n+2){ margin-top : 30px; }
                        @include flex();
                        .name{
                            width : 90px;
                            &.profile01 span{ color : $color-talk01; }
                            &.profile02 span{ color : $color-talk02; }
                            &.profile03 span{ color : $color-talk03; }

                            p{ @include fonts(14px, 28px, $fontweightSemiBold, 0); }
                        }
                        .txt{
                            width : calc(100% - 90px);
                            p{
                                @include fonts(14px, 28px, $fontweightRegular, 0);
                                @include textJustify;
                            }
                        }
                    }
                }

                .segs .seg:nth-of-type(n+2){ margin-top : 80px; }
                .boxes .box:nth-of-type(n+2){ margin-top : 30px; }

                .bottom-img{
                    margin  : 50px 0 0;
                    padding : 0 50px;
                }
            }
        }
    }

    #talk-message{
        position : relative;
        padding  : 360px 40px 45px;
        .bg{
            z-index         : -1;
            @include absoluteTL;
            width           : 100%;
            height          : 400px;
            background      : url(../images/talk/talk_sec04_p01_pc.jpg) no-repeat center;
            background-size : cover !important;
        }

        .profile{
            li{
                &:nth-of-type(n+2){ margin-left : 3.3%; }
                &:nth-of-type(1){
                    border-bottom : 4px solid $color-talk01;
                    .name p{ color : $color-talk01; }
                }
                &:nth-of-type(2){
                    border-bottom : 4px solid $color-talk02;
                    .name p{ color : $color-talk02; }
                }
                &:nth-of-type(3){
                    border-bottom : 4px solid $color-talk03;
                    .name p{ color : $color-talk03; }
                }

                padding      : 30px 30px 35px;
                width        : 48.2%;
                background   : #f6f6f6;
                border-right : 1px solid #dcdcdc;
                border-top   : 1px solid #dcdcdc;
                border-left  : 1px solid #dcdcdc;

                .name{
                    p{ @include fonts(14px, 1em, $fontweightSemiBold, 0); }
                }
                .message{
                    margin : 10px 0 0;
                    p{ @include fonts(24px, 30px, $fontweightRegular, 0, null, $fontFamilyNotoSerif); }
                }
                .txt{
                    margin : 15px 0 0;
                    p{
                        @include fonts(12px, 22px, $fontweightRegular, 0);
                        @include textJustify;
                    }
                }
            }
        }
    }

    span.marker{ background: #91ffb0; }
    span.stext{font-size: 12px;}
}

@media #{$small}{
    #main.new_talk{
        #h1{
            margin  : 0 0 40px;
            padding : 0 20px;
            h1{
                padding    : 25px 0 25px 70px;
                @include fonts(23px, 27px);
                text-align : left;
                .small{
                    @include fonts(15px, 1em, null, 0);
                    transform : translate(0, 0);
                }
                &:before{
                    @include absolute(0, null, 0, 20px, auto);
                    width      : 36px;
                    height     : 26px;
                    background : url(../images/talk/talk_icon_h1_sp.png) no-repeat 0 0;
                }
            }
        }

        #talk-profile{
            padding : 65.3% 40px 0;
            .bg{
                padding-bottom : 86.6%;
                width          : 100%;
                height         : auto;
                background     : url(../images/talk/talk_mv_sp.jpg) no-repeat center;
            }

            .profile{
                li{
                    &:nth-of-type(n+2){ margin : 10px 0 0; }
                    &:nth-of-type(1){ border-top : 2px solid $color-talk01; }
                    &:nth-of-type(2){ border-top : 2px solid $color-talk02; }
                    &:nth-of-type(3){ border-top : 2px solid $color-talk03; }

                    position : relative;
                    padding  : 10px 20px 20px;
                    width    : 100%;

                    .spshow{
                        @include absolute(10px, null, null, 20px);
                        img{ width : 50px; }
                    }
                    .name{
                        padding-top  : 5px;
                        padding-left : 60px;
                        p{ @include fonts(18px); }
                    }
                    .occupation{
                        margin       : 10px 0 0;
                        padding-left : 60px;
                        p{ @include fonts(10px); }
                    }
                    .txt{
                        margin : 20px 0 0;
                        p{ @include fonts(11px, 18px); }
                    }
                }
            }
        }

        #talk-main{
            margin  : 50px 0 0;
            padding : 0 20px;
            .block{
                &:nth-of-type(n+2){ margin : 50px 0 0; }
                padding : 0;
                border  : none;
                .h2{
                    position : relative;
                    &:before,
                    &:after{
                        content    : '';
                        @include absolute(0, null, 0, null, auto);
                        width      : 10px;
                        height     : 10px;
                        background : #fff;
                    }
                    &:before{ left : 20px; }
                    &:after{ right : 20px; }

                    h2{
                        @include fonts(19px, 27px, null, 0);
                        &:before,
                        &:after{ display : none }
                    }
                }

                .txtbody{
                    margin : 25px 0 0;
                    .h3{
                        margin  : 0 0 15px;
                        padding : 0 0 0 70px;
                        &:before{
                            @include absolute(0, null, auto, 0, auto);
                        }
                        &:after{
                            @include absolute(7px, null, auto, 15px, auto);
                        }
                        h3{
                            transform : translateY(-2px);
                            @include fonts(14px, 21px, null, 0);
                        }
                    }

                    .flexbox.img{
                        &--right{
                            .col:nth-of-type(1){ width : 100%; }
                            .col:nth-of-type(2){
                                margin : 15px 0 0;
                                width  : 100%;
                                .ph{
                                    transform  : translate(0);
                                    text-align : center;
                                    img{ width : 210px; }
                                }
                            }
                        }
                        &--left{
                            .col:nth-of-type(1){
                                width : 100%;
                                .ph{
                                    transform  : translate(0);
                                    text-align : center;
                                    img{ width : 210px; }
                                }
                            }
                            .col:nth-of-type(2){
                                margin : 15px 0 0;
                                width  : 100%;
                            }
                            &.reversal{
                                .col:nth-of-type(1){
                                    order      : 2;
                                    margin-top : 15px;
                                }
                                .col:nth-of-type(2){
                                    order      : 1;
                                    margin-top : 0;
                                }
                            }
                        }
                    }

                    .talk-list{
                        li{
                            &:nth-of-type(n+2){ margin-top : 15px; }
                            .name{
                                width : 70px;
                                p{ @include fonts(12px, 20px); }
                            }
                            .txt{
                                width : calc(100% - 70px);
                                p{ @include fonts(12px, 20px); }
                            }
                        }
                    }

                    .segs .seg:nth-of-type(n+2){ margin-top : 40px; }
                    .boxes .box:nth-of-type(n+2){ margin-top : 15px; }

                    .bottom-img{
                        margin  : 20px 0 0;
                        padding : 0;
                    }
                }
            }
        }

        #talk-message{
            margin  : 50px 0 0;
            padding : 53.3% 40px 15px;
            .bg{
                padding-bottom : 61.3%;
                height         : auto;
                background     : url(../images/talk/talk_sec04_p01_sp.jpg) no-repeat center;
            }

            .profile{
                li{
                    &:nth-of-type(n+2){ margin : 10px 0 0; }
                    &:nth-of-type(1){ border-bottom : 2px solid $color-talk01; }
                    &:nth-of-type(2){ border-bottom : 2px solid $color-talk02; }
                    &:nth-of-type(3){ border-bottom : 2px solid $color-talk03; }

                    position : relative;
                    padding  : 10px 20px 20px;
                    width    : 100%;

                    .spshow{
                        @include absolute(10px, null, null, 20px);
                        img{ width : 50px; }
                    }
                    .name{
                        padding-left : 65px;
                        p{ @include fonts(13px); }
                    }
                    .message{
                        margin       : 5px 0 0;
                        padding-left : 65px;
                        p{ @include fonts(18px, 23px); }
                    }
                    .txt{
                        margin : 15px 0 0;
                        p{ @include fonts(11px, 18px); }
                    }
                }
            }
        }
    }
}
