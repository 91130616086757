@charset "utf-8";
@import "../../core/_variable.scss";

/* ================================================================================
    #main.new_number
================================================================================ */
#main.new_number{
    .h1{
        margin : 0 0 50px;
        h1{
            position   : relative;
            padding    : 60px 0 60px 125px;
            background : #fff url(../images/number/number_h1_bg_pc.jpg) no-repeat 0 0;
            border     : 1px solid #dcdcdc;
            @include fonts(44px, 1em, null, 0.05em, #ec7071);
            text-align : left;
            &:before{
                content         : '';
                @include absolute(0, null, 0, 40px, auto);
                width           : 52px;
                height          : 60px;
                background      : url(../images/number/number_icon_h1_pc.png) no-repeat 0 0;
                background-size : 100% auto !important;
            }
        }
    }
    
    .blocks{
        margin : 20px 0 60px;
        .block{
            .h2{
                padding       : 60px 0 50px;
                background    : #fff;
                border        : 1px solid #ffa2a3;
                border-bottom : none;
                text-align    : center;
                h2{
                    position : relative;
                    display  : inline-block;
                    @include fonts(32px, 1em, $fontweightSemiBold, 0.05em);
                    &:before{
                        content    : '';
                        @include absolute(null, 0, -15px, 0, auto);
                        width      : 100%;
                        height     : 1px;
                        background : #ec7071;
                    }
                }
                p{
                    margin : 50px 0 0;
                    @include fonts(16px, 28px, $fontweightSemiBold, 0.05em);
                }
            }
            
            ul{
                border-right  : 1px solid #ffa2a3;
                border-bottom : 1px solid #ffa2a3;
                & > li{
                    padding     : 20px 45px 0;
                    width       : 50%;
                    height      : 480px;
                    border-left : 1px solid #ffa2a3;
                    border-top  : 1px solid #ffa2a3;
                    background  : #fff;
                    .no{
                        text-align : center;
                        p{
                            display       : inline-block;
                            width         : 50px;
                            height        : 50px;
                            background    : #ec7071;
                            border-radius : 50%;
                            @include fonts(20px, 50px, $fontweightSemiBold, 0.05em, #fff);
                        }
                    }
                    h3{
                        margin     : 15px 0 0;
                        @include fonts(28px, 1em, $fontweightSemiBold, 0.05em);
                        text-align : center;
                    }
                    p{
                        @include fonts(14px, 26px, $fontweightRegular);
                    }
                }
                .sp-no{
                    background: none;
                }
            }
            .number_att{
                padding: 20px 0 0 0;
                line-height: 1.7em;
            }
        }
    }
}

@media #{$small}{
    #main.new_number{
        .h1{
            margin  : 0 0 40px;
            padding : 0 20px;
            h1{
                padding         : 30px 0 30px 50px;
                background      : #fff url(../images/number/number_h1_bg_sp.jpg) no-repeat right center;
                background-size : cover !important;
                @include fonts(22px, 27px);
                text-align      : left;
                &:before{
                    @include absolute(0, null, 0, 15px, auto);
                    width      : 26px;
                    height     : 30px;
                    background : url(../images/number/number_icon_h1_sp.png) no-repeat 0 0;
                }
            }
        }
        
        .blocks{
            padding : 0 20px;
            .block{
                .h2{
                    padding    : 0 0 25px;
                    background : none;
                    border     : none;
                    h2{
                        @include fonts(23px, null, null, 0);
                        &:before{
                            @include absolute(null, 0, -10px, 0, auto);
                        }
                    }
                    p{
                        margin     : 25px 0 0;
                        @include fonts(14px, 25px, null, 0);
                        text-align : left;
                    }
                }
                
                ul{
                    & > li{
                        padding : 20px 20px 30px;
                        width   : 100%;
                        height  : auto;
                        .no{
                            p{
                                padding : 0 0 0 2px;
                                width   : 40px;
                                height  : 40px;
                                @include fonts(18px, 38px);
                            }
                        }
                        h3{
                            margin     : 10px 0 0;
                            @include fonts(25px);
                            text-align : center;
                        }
                        .icon img{ width : 100%; }
                        p{
                            @include fonts(12px, 20px);
                        }
                    }
                    .sp-no{
                        display: none;
                    }
                }
            }
        }
    }
}
