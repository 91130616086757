@charset "utf-8";
@import "../../core/_variable.scss";

/* ================================================================================
    #main.new_welfare
================================================================================ */
#main.new_welfare{
    .h1{
        margin : 0 0 50px;
        h1{
            position   : relative;
            padding    : 60px 0 60px 125px;
            background : #fff url(../images/welfare/welfare_h1_bg_pc.jpg) no-repeat 0 0;
            border     : 1px solid #dcdcdc;
            @include fonts(44px, 1em, null, 0.05em, #ec7071);
            text-align : left;
            &:before{
                content         : '';
                @include absolute(0, null, 0, 40px, auto);
                width           : 52px;
                height          : 60px;
                background      : url(../images/welfare/welfare_icon_h1_pc.png) no-repeat 0 0;
                background-size : 100% auto !important;
            }
        }
    }
    
    .blocks{
        margin : 60px 0;
        .block{
            margin : 40px 0 0;
            
            .h2{
                margin     : 0 0 70px;
                text-align : center;
                h2{
                    position : relative;
                    display  : inline-block;
                    @include fonts(32px, 1em, $fontweightSemiBold, 0.05em);
                    &:before{
                        content    : '';
                        @include absolute(null, 0, -15px, 0, auto);
                        width      : 100%;
                        height     : 1px;
                        background : #ec7071;
                    }
                }
            }
            
            ul > li{
                &:nth-of-type(3n+1){ margin-left : 0; }
                &:nth-of-type(n+4){ margin-top : 30px; }
                &:last-of-type{
                    margin-left : 0;
                    width       : 100%;
                    h3{
                        height : auto;
                    }
                    text-align  : center;
                    .box{
                        display   : inline-block;
                        padding   : 0 0 10px;
                        transform : translateX(30px);
                        .left, .right{
                            width      : 190px;
                            text-align : left;
                            p{
                                @include fonts(14px, 22px, $fontweightMedium);
                            }
                        }
                    }
                }
                margin-left : 2.6%;
                padding     : 20px 25px;
                width       : 31.6%;
                border      : 1px solid #ffa2a3;
                border-top  : 2px solid #ec7071;
                background  : #fff;
                h3{
                    height     : 60px;
                    @include fonts(20px, 30px, $fontweightSemiBold, 0.1em);
                    text-align : center;
                }
                .icon{
                    text-align : center;
                }
                p{
                    margin : 20px 0 0;
                    @include fonts(14px, 22px, $fontweightMedium);
                }
            }
        }
    }
}

@media #{$small}{
    #main.new_welfare{
        .h1{
            margin  : 0 0 40px;
            padding : 0 20px;
            h1{
                padding         : 30px 0 30px 50px;
                background      : #fff url(../images/welfare/welfare_h1_bg_sp.jpg) no-repeat right center;
                background-size : cover !important;
                @include fonts(22px, 27px);
                text-align      : left;
                &:before{
                    @include absolute(0, null, 0, 15px, auto);
                    width      : 26px;
                    height     : 30px;
                    background : url(../images/welfare/welfare_icon_h1_sp.png) no-repeat 0 0;
                }
            }
        }
        
        .blocks{
            margin  : 40px 0;
            padding : 0 20px;
            .block{
                margin : 40px 0 0;
                
                .h2{
                    margin : 0 0 40px;
                    h2{
                        @include fonts(23px, null, null, 0);
                        &:before{
                            @include absolute(null, 0, -10px, 0, auto);
                        }
                    }
                }
                
                ul > li{
                    &:nth-of-type(3n+1){ margin-left : 0; }
                    &:nth-of-type(n+2){ margin-top : 20px; }
                    &:last-of-type{
                        margin-left : 0;
                        width       : 100%;
                        h3{
                            height : auto;
                        }
                        text-align  : center;
                        .box{
                            display   : inline-block;
                            padding   : 0 0 10px;
                            width     : 100%;
                            transform : translateX(0);
                            .left, .right{
                                width : 50%;
                                p{
                                    @include fonts(12px, 20px, $fontweightRegular);
                                }
                            }
                        }
                    }
                    margin-left : 0;
                    padding     : 25px 20px 20px;
                    width       : 100%;
                    h3{
                        height : auto;
                        @include fonts(25px, 31px, null, 0.05em);
                    }
                    .icon{
                        margin : 15px 0 0;
                        img{ width : 90px; }
                    }
                    p{
                        margin : 15px 0 0;
                        @include fonts(12px, 20px, $fontweightRegular);
                    }
                }
            }
        }
    }
}
