@charset "utf-8";
@import "../../core/_variable.scss";

/* ================================================================================
    #main.new_number
================================================================================ */
a.cs{ pointer-events : none; }

#main.new_voice{
    .voiceLineBoxCustom{
        margin  : 0 0 60px;
        padding : 0 40px 70px;
        border  : 1px solid #99d6b4;
    }

    .page-links{
        text-align : center;
        .page-links-wrap{
            display : inline-block;
            ul{ border-right : 1px solid #99d6b4; }
            li{
                width         : 180px;
                border-left   : 1px solid #99d6b4;
                border-bottom : 1px solid #99d6b4;
                a{
                    display         : block;
                    height          : 70px;
                    @include fonts(15px, 70px, $fontweightBold, 0.05em, #99d6b4);
                    text-decoration : none;
                    @include trans();
                    &:hover{
                        color      : #fff;
                        background : #99d6b4;
                    }
                }
            }
        }
    }

    .voice-article{
        .voice-section{
            padding : 110px 0 0;
            .h2{
                text-align : center;
                h2{
                    display       : inline-block;
                    padding       : 0 0 10px;
                    @include fonts(36px, 1em, $fontweightBold, 0);
                    border-bottom : 1px solid #99d6b4;
                }
            }

            .blocks{
                margin : 60px 0 0;
                .block{
                    &:nth-of-type(n+2){
                        margin     : 40px 0 0;
                        padding    : 120px 0 0;
                        border-top : 1px dotted #919191;
                    }

                    .col:nth-of-type(1){
                        width : 47.7%;
                    }
                    .col:nth-of-type(2){
                        width   : 52.3%;
                        padding : 0 0 0 40px;
                    }
                    a{
                        @include trans(0.3);
                        &:hover{ opacity : 0.8; }
                    }
                }

                .txt-set{
                    padding : 20px 20px 0;
                    .name{
                        @include fonts(28px, 1em);
                    }
                    .join{
                        margin : 15px 0 0;
                        @include fonts(12px, 1em);
                    }
                    .more{
                        margin : 20px 0 0;
                        p{
                            display : inline-block;
                            padding : 10px 20px;
                            border  : 1px solid #cbcbcb;
                            @include fonts(14px, 1em);
                        }
                    }
                }

                .box{
                    @include nthTopMarginZero;
                    margin : 35px 0 0;
                    h3{
                        position : relative;
                        padding  : 0 0 0 80px;
                        @include fonts(16px, 1em, $fontweightBold, 0, #74b892);
                        &:before{
                            content    : '';
                            @include absolute(0, null, 0, 0, auto);
                            width      : 70px;
                            height     : 1px;
                            background : #74b892;
                        }
                    }
                    p{
                        margin : 10px 0 0;
                        @include fonts(14px, 26px);
                    }
                }
            }

            .voice-schedule{
                margin     : 60px 0 0;
                padding    : 40px 50px 30px;
                border     : 1px solid #dbdbdb;
                border-top : 4px solid #74b892;
                h4{
                    margin : 0 0 40px;
                    @include fonts(24px, 1em, $fontweightBold);
                    .small{ font-size : 18px; }
                }
            }
        }
    }
    .voice_talk_bnr{
        padding: 100px 0 0 0;
    }
}

@media #{$small}{
    #main.new_voice{
        .voiceLineBoxCustom{
            margin  : 0 20px 50px;
            padding : 0;
            border  : none;
        }

        .page-links{
            text-align : center;
            .page-links-wrap{
                display : block;
                ul{
                    border-top   : 1px solid #99d6b4;
                    border-right : 1px solid #99d6b4;
                }
                li{
                    width         : 100%;
                    border-left   : 1px solid #99d6b4;
                    border-bottom : 1px solid #99d6b4;
                    a{
                        display : block;
                        height  : 50px;
                        @include fonts(14px, 50px, null);
                    }
                }
            }
        }

        .voice-article{
            .voice-section{
                padding : 50px 0 0;
                .h2{
                    h2{
                        padding : 0 0 5px;
                        @include fonts(18px, 1em);
                    }
                }

                .blocks{
                    margin : 30px 0 0;
                    .block{
                        &:nth-of-type(n+2){
                            margin  : 25px 0 0;
                            padding : 25px 0 0;
                        }

                        .col:nth-of-type(1){
                            padding : 0 30px;
                            width   : 100%;
                        }
                        .col:nth-of-type(2){
                            padding : 30px 0 0;
                            width   : 100%;
                        }
                    }

                    .txt-set{
                        padding : 20px 0 0;
                        .name{
                            @include fonts(16px, 1em);
                        }
                        .join{
                            margin : 10px 0 0;
                            @include fonts(12px, 1em);
                        }
                        .more{
                            margin : 15px 0 0;
                            p{
                                display    : block;
                                padding    : 10px 20px;
                                @include fonts(11px, 1em);
                                text-align : center;
                            }
                        }
                    }

                    .box{
                        @include nthTopMarginZero;
                        margin : 35px 0 0;
                        h3{
                            padding : 0 0 0 40px;
                            @include fonts(14px, 1em);
                            &:before{
                                width : 30px;
                            }
                        }
                        p{
                            margin : 10px 0 0;
                            @include fonts(12px, 20px);
                        }
                    }
                }

                .voice-schedule{
                    margin  : 30px 0 0;
                    padding : 20px 20px;
                    h4{
                        margin : 0 0 20px;
                        @include fonts(14px, 1em);
                        .small{ font-size : 12px; }
                    }
                }
            }
        }
        .voice_talk_bnr{
            padding: 20px 0 0 0;
        }
    }
}
