@charset "utf-8";
@import "../../core/_variable.scss";

/* ================================================================================
    /index.html
================================================================================ */
#voice{
    .bnr_area{
        display : block !important;
        margin  : 50px 0 0;
        width   : 100%;
        img{ width : 600px; }
    }
    .float{
        margin : 20px 0 0;
        width  : 100%;
        .left,
        .right{
            width : 430px;
            .voiceTitleS{
                margin        : 0 0 30px;
                padding       : 0 0 10px;
                border-bottom : 1px solid #74b892;
                text-align    : center;
                span{ @include fonts(20px, 1em, $fontweightBold, 0); }
            }

            .col{
                width : 45.3%;
                &:nth-of-type(n+2){ margin-left : 9.4%; }
                a.cs{ pointer-events : none; }
            }
            .denwa{
                margin: 0 auto;
            }
        }
    }
}

@media #{$small}{
    #voice{
        .bnr_area{
            margin  : 25px 0 0;
            padding : 0 !important;
            width   : 100%;
            img{ width : 100% !important; }
        }
        .float{
            margin : 25px 0 0;
            .left,
            .right{
                width : 100%;
                .voiceTitleS{
                    margin : 0 0 20px;
                    span{ @include fonts(15px); }
                }

                .col{
                    width : 47.7%;
                    &:nth-of-type(n+2){ margin-left : 4.6%; }
                }
            }
            .right{ margin : 25px 0 0; }
        }
    }
}

/* ================================================================================
    /about.html
================================================================================ */
.about_sa_bl2 dt,
.about_sa_bl2 dd{
    padding-bottom : 0;
}

.about-youtube{
    margin : 50px 0 0;
    p{
        margin  : 0;
        padding : 0;
    }
    .txt-set{
        .ttl{
            @include fonts(24px, 1em, $fontweightBold, 0.05em, #ec7071);
        }
        .txt{
            margin : 10px 0 0;
            @include fonts(14px, 1em);
        }
    }
    .area-movie{
        margin : 15px 0 0;
        width  : 360px;
        .ttl{
            padding    : 10px 20px;
            background : #ec7071;
            @include fonts(14px, 1em, $fontweightBold, 0, #fff);
        }
        a{
            display : block;
            @include trans(0.3);
            &:hover{ opacity : 0.8; }
        }
        .about-youtube-preview{ margin : 15px 0 0; }
    }
}


.about{
    // override
    #about_sa{
        .about_sa_bl2{
            #communicator{ margin : 0; }
            #office_staff{ margin : 20px 0 0; }
        }
        .about_sa_bl3{ margin : 60px 0 0; }
        .att-txt{font-size: 13px; line-height: 1.5em;}
    }

    #about_training,
    #fukurikousei,
    #info-session,
    #about_data{
        margin : 100px 0 0;
    }
}

@media #{$small}{
    .about-youtube{
        margin  : 0 0 45px;
        padding : 0 0 0;
        .txt-set{
            .ttl{
                @include fonts(17px);
            }
            .txt{
                margin : 10px 0 0;
                @include fonts(12px);
            }
        }
        .area-movie{
            margin  : 15px 0 0;
            padding : 0 30px;
            width   : 100%;
            .ttl{
                padding : 5px 10px;
                @include fonts(10px);
            }
            .about-youtube-preview{ margin : 15px 0 0; }
        }
    }

    .about{
        // override
        #about_sa{
            .about_sa_bl2{
                #communicator{ margin : 0; }
                #office_staff{ margin : 10px 0 0; }
            }
            .about_sa_bl3{ margin : 40px 0 0; }
        }

        #about_training,
        #fukurikousei,
        #info-session,
        #about_data{
            margin : 50px 0 0;
        }

        #info-session{
            .txt{ width : 100%; }
            p{ @include fonts(12px, 20px); }
        }
    }
}
