@charset "utf-8";
@import "../../core/_variable.scss";

/* ================================================================================
    #main.new_number
================================================================================ */
#main.o_mitaka{
    h1,
    .titleC span,
    .sapporoLineBox,
    .sapporoH3 span,
    .sapporoSub li{
        border-color: #3fd57b !important;
    }
    
    h1,
    .sapporoSub a{ color: #2cc067 !important; }
    .sapporoSub a:hover {
        color: #fff !important;;
        background-color: #2cc067 !important;;
    }
    
    .sapporo_schedule th,
    .sapporo_schedule td {
        border-color: #dfdfdf !important;
    }
    
    .sapporo_schedule th {
        background: #dcfbe8 !important;
        color: #2cc067 !important;
        white-space: nowrap;
    }
    
    .ttl.bold{ font-size: 16px; }
    .sapporo_data .data_box:nth-of-type(1) .txt{
        text-align: center;
    }
    span.small{ font-size: 12px; }
    .data-att{
        width: 100%;
        padding: 0 0 50px 0;
        text-align: right;
        font-size: 11px;
    }
    #mitaka1,#mitaka2,#mitaka3,#mitaka4,#mitaka5{
        margin-top: -100px;
        padding-top: 100px;
    }
    
}
@media #{$small}{
    #main.o_mitaka{
        .ttl.bold{ font-size: 13px; }
        .data-att{
            width: 100%;
            padding: 15px 0 5px 0;
            text-align: right;
            font-size: 10px;
        }
        #mitaka1,#mitaka2,#mitaka3,#mitaka4,#mitaka5{
            margin-top: -10px;
            padding-top: 10px;
        }
    }

}
